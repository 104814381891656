<template>
    <div>
      <v-divider></v-divider>
      <v-layout wrap fill-height>
        <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
          <v-navigation-drawer
            class="fixedSidebar"
            clipped
            width="100%"
            height="100vh"
            permanent
            style="background-color: #fafafa"
          >
            <v-layout style="background-color: #fff; height: 85px !important">
              <v-flex xs12 pl-4 align-self-center>
                <v-img contain width="95%" height="90%" src="../assets/sarpaodisha_1.png" fill-height></v-img>
                <!-- <v-toolbar-title
                  class="pt-3"
                  style="font-family:opensansextrabold
                letter-spacing: 0.63px;
              color: #000000;"
                  >WILD WATCH</v-toolbar-title
                > -->
              </v-flex>
            </v-layout>
  
            <v-list dense nav>
              <v-list-item
                v-for="item in items"
                :key="item.title"
                style="letter-spacing: 0.63px"
                link
                class="pr-0"
                :to="item.path"
               
              >
                <v-list-item-icon>
                  <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                  <v-icon class="pr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title
                    style="
                      font-size: 14px;
                      font-family: poppinsmedium;
                    "
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs12 sm12 md10 lg10>
          <v-layout wrap pb-0 mb-0>
            <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
              <AppHeader class="pb-0 mb-0" />
            </v-flex>
            <v-flex xs12 pt-0 mt-0
              ><v-divider class="pt-0 mt-0"></v-divider
            ></v-flex>
            <v-flex xs12 sm12 md12 lg12 pl-3>
              <v-layout
                wrap
                justify-center
                :style="
                  $route.name == 'dashboard'
                    ? 'background-color: #ffffff'
                    : 'background-color: #f6fff9'
                "
              >
                <v-flex xs12>
                  <v-card
                    min-height="100vh"
                    :color="
                      $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                    "
                    flat
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <router-view :key="$route.fullPath"></router-view>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import AppHeader from "@/components/Common/appHeadersarpa";
  
  export default {
    components: {
      AppHeader,
    },
    data() {
      return {
        // items: [
        // {
        //     title: "Dashboard",
        //     icon: "mdi-view-dashboard",
        //     path: "/medicalOfficers/dmoDashboard",
        // },
        // {
        //     title: "Stock Details",
        //     icon: "mdi-medical-bag",
        //     path: "/medicalOfficers/stockVenom",
        // },
        // {
        //     title: "Victim Details",
        //     icon: "mdi-hospital-box",
        //     path: "/medicalOfficers/victimDetails",
        // },
  
        // ],

        items: [
      {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/DistrictEmergencyOfficer/districtDashboard",
        },
        // {
        //   title: "New Rescuers",
        //   icon: "mdi-account-plus",
        //   path: "/officers/newrescuerslist",
        // },
        {
          title: "Rescuer List",
          icon: "mdi-account-multiple-plus ",
          path: "/DistrictEmergencyOfficer/allrescuerlistDis",
        },
        {
          title: "DMO List",
          icon: "mdi-doctor",
          path: "/DistrictEmergencyOfficer/alldmolist",
        },
        {
          title: "Pending Operations",
          icon: "mdi-playlist-remove",
          path: "/DistrictEmergencyOfficer/pendingoperations",
        },
        {
          title: "Assigned Operations",
          icon: "mdi-view-list",
          path: "/DistrictEmergencyOfficer/assignedoperations",
        },
        {
          title: "Rescued Operations",
          icon: "mdi-playlist-plus",
          path: "/DistrictEmergencyOfficer/rescuedoperations",
        },
        {
          title: "Released Operations",
          icon: "mdi-format-list-checks",
          path: "/DistrictEmergencyOfficer/releasedoperations",
        },
        {
          title: "Approved Operations",
          icon: "mdi-playlist-check",
          path: "/DistrictEmergencyOfficer/approvedoperations",
        },
        {
          title: "Delete Operations",
          icon: "mdi-delete-sweep",
          path: "/DistrictEmergencyOfficer/deleteoperations",
        },
        {
          title: "Control Room ",
          icon: "mdi-file-account",
          path: "/DistrictEmergencyOfficer/controlRoomList",
        },
        // {
        //   title: "Emergency Officers",
        //   icon: "mdi-account-hard-hat",
        //   path: "/DistrictEmergencyOfficer/alldistrictlist",
        // },
        {
          title: "Snake Bite Cases",
          icon: "mdi-hospital-box-outline",
          path: "/DistrictEmergencyOfficer/snakeBiteDis",
        },
        {
          title: "Fraud Cases",
          icon: "mdi-close-box-multiple",
          path: "/DistrictEmergencyOfficer/fraudcases",
        },

      ],
        right: null,
      };
    },
  };
  </script>